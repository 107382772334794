<template>
    <el-dialog :title="'下载文件'" :close-on-click-modal="false" :visible.sync="subVisible" width="700px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px">
            <el-row v-for="(file, index) in dataForm.files" style="margin-top:10px;margin-left: 40px;">
                <el-col :span="24">
                    <el-col :span="18">
                        <el-button v-preventReClick type="text" size="small" style="color: #00428e;"
                            @click="viewPdf(file.filePath, file.fileUri, file.fileName)">
                            {{file.fileName }}</el-button>
                    </el-col>
                    <el-col :span="6">
                    <el-button v-preventReClick type="primary" size="small" style="margin-top:-10px;margin-left: 50px;"
                        @click="downloadFile(file.fileUri, file.fileName)">下载</el-button>
                    </el-col>
                </el-col>
            </el-row>
        </el-form>
        <el-divider></el-divider>
        <view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
    </el-dialog>
</template>
<script>
import $common from "@/utils/common.js"
import ViewPdf from "../projectInfo/viewPdf.vue"
export default {
    name: "weekly-file-upload",
    data() {
        return {
            viewPdfVisible: false,
            id: "",
            subVisible: true,
            dataForm: {
                recNo: '',
                files: []
            },
            dataRule: {
            }
        };
    },
    components: {
        ViewPdf
    },
    activated() {

    },
    methods: {
        init(id) {
            this.dataForm = {
                recNo: id,
                files: []
            }
            this.dataForm.recNo = id
            this.getDetail();
            this.$nextTick(() => {
                this.subVisible = true;
            })
            this.$refs['dataForm'].resetFields();
        },
        viewPdf(filePath, fileUri, filename) {
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
                this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
        downloadFile(fileUri, filename) {
            filename = this.getSuffix(fileUri, filename)
            this.getBlob(fileUri, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },

        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + `/business/fileexamineinfo/info/${this.dataForm.recNo}`,
                method: 'get',
                params: this.$http.adornParams({})
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.files = data.body.files;
                }
            })
        },
    }
};
</script>

<style lang="scss" scoped="scoped">
.tmp {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 300px;
    display: inline-block;
    color: #00428e;
    // margin-top: 13px;
}
</style>
